import React from "react"

import { Layout, DownloadFrom, SEO } from "../components"
import {
  tick,
  ios3,
  ad3,
  forbes,
  like,
  star,
  noOne,
  aboutPhones,
  bigTick,
} from "../assets"
import "./main.css"
import "./about.css"

const About = () => {
  return (
    <Layout>
      <SEO 
        title="OKauto - что это за проект | Сайт проверки штрафов в Казахстане" 
        description="Мы - первое мобильное приложение для проверки и оплаты штрафов ПДД в Казахстане. Наш сервис уже является одним из самых популярных мобильных приложений в AppStore и Google PlayMarket по стране и мы продолжаем совершенствоваться"
      />

      <div className=" pt6-ns  ph6-l pages__padding ph5-m ph4  lh-copy animation">
        <div className="pt4-l b about__the__project">
          <p className=" f3-l f4 w-70-l mb5-l ipad__font__text">
            OKauto - первое мобильное приложение в Казахстане, которое
            предоставляет сервис проверки, уведомления и оплаты штрафов ПДД.
            <span className="dn dib-l">
              Приложение запустилось в марте 2017 года и заняло 13 место в
              рейтинге ТОП-30 мобильных сервисов 2018 года по версии Forbes
              Kazakhstan.
            </span>
          </p>
        </div>

        <div className="dt-row-l flex flex-column">
          <div className="dtc w-20-l w-100 pr4">
            <img
              alt="noOne"
              title="noOne"
              src={noOne}
              className=" mb1 self-start"
            />
            <p className=" ipad__font__citation">
              Сейчас каждый девятый автовладелец использует наше приложение. В
              настоящее время мы являемся сервисом № 1 в Казахстане.
            </p>
          </div>
          <div className="dtc w-20-l w-100 pr4">
            <img
              alt="forbes"
              title="forbes"
              src={forbes}
              className=" mb1 self-start"
            />
            <p className=" ipad__font__citation">
              Также на сегодняшний день мы являемся самым быстрорастущим
              стартапом в Казахстане по версии Forbes.
            </p>
          </div>
          <div className="dtc w-20-l pr4">
            <img alt="ios3" title="ios3" src={ios3} className="mb1 mr2" />
            <img alt="ad3" title="ad3" src={ad3} className="mb1" />
            <p className=" ipad__font__citation">
              Мы достигли свыше 800 тыс. загрузок в AppStore и Google Play, и
              более 200 тыс. активных пользователей.
            </p>
          </div>
          <div className="dtc w-20-l pr4">
            <img alt="star" title="star" src={star} className="mb1 mr2" />
            <img alt="like" title="like" src={like} className="mb1" />
            <p className=" ipad__font__citation">
              Основная цель OKauto - создавать полезные продукты, которые
              сделают жизнь автовладельцев лучше и проще.
            </p>
          </div>
        </div>

        <h1 className="flex-wrap w-100 f1-l f2-m f3 ipad__font__header b mb0 mt5-l mt3">
          Преимущества OKauto
        </h1>
        <ul className="list f5-l ml0 pa0-l flex-ns flex-wrap-ns mb0 b mt5-l mt4">
          <li className=" pl0 flex mb3 w-33-l w-50-m mb0-l mb4-l">
            <img alt="tick" title="tick" src={tick} className="mr3 dn-l" />
            <img
              alt="bigTick"
              title="bigTick"
              src={bigTick}
              className="mr3 w2 h2 db-l dn"
            />

            <span className="list__items__font">
              Уведомление о новых штрафах
            </span>
          </li>
          <li className="flex mb3 w-33-l w-50-m mb0-l mb4-l">
            <img alt="tick1" title="tick1" src={tick} className="mr3 dn-l" />
            <img
              alt="bigTick1"
              title="bigTick1"
              src={bigTick}
              className="mr3 w2 h2 db-l dn"
            />
            <span className="list__items__font">
              Фотоматериалы и место нарушения
            </span>
          </li>
          <li className="flex mb3 w-33-l w-50-m mb0-l">
            <img alt="tick2" title="tick2" src={tick} className="mr3 dn-l" />
            <img
              alt="bigTick2"
              title="bigTick2"
              src={bigTick}
              className="mr3 w2 h2 db-l dn"
            />

            <span className="list__items__font">
              Гарантия погашения штрафов
            </span>
          </li>
          <li className="flex mb3 w-33-l w-50-m mb0-l mb4-l">
            <img alt="tick3" title="tick3" src={tick} className="mr3 dn-l" />
            <img
              alt="bigTick3"
              title="bigTick3"
              src={bigTick}
              className="mr3 w2 h2 db-l dn"
            />

            <span className="list__items__font pr2">Актуальная информация</span>
          </li>
          <li className="flex mb3 w-33-l w-50-m mb0-l">
            <img alt="tick4" title="tick4" src={tick} className="mr3 dn-l" />
            <img
              alt="bigTick4"
              title="bigTick4"
              src={bigTick}
              className="mr3 w2 h2 db-l dn"
            />

            <span className="list__items__font">Квитанция об оплате</span>
          </li>
        </ul>
      </div>

      <div className="flex flex-row-l flex-column pl6-l">
        <div className="flex-column w-60-l w-100 z-1 justify-center">
          <div className="mt5-l pl0-l pl5-m ">
            <DownloadFrom />
          </div>
        </div>
        <img
          alt="фото приложение бесплатная проверка штрафов онлайн в Казахстане"
          title="OKauto бесплатное приложение для проверки и оплаты штрафов РК"
          src={aboutPhones}
          className=" mb0 relative-l right-0 bottom-0 h-100-l w-50-l w-100 "
        />
      </div>
    </Layout>
  )
}

export default About
